import Vue from 'vue'
import Vuex from 'vuex'
import CreateAllocation from './createAllocation'
import Dashboard from './dashboard'
import { login } from './login'

Vue.use(Vuex)

const store = () => {
  return new Vuex.Store({
    modules: {
      CreateAllocation,
      Dashboard,
      login
    }
  })
}

export default store

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store'
import multiselect from 'vue-multiselect'
import VueGtag from 'vue-gtag'
// vue element loader
import VueElementLoading from 'vue-element-loading'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VuejsDialog from 'vuejs-dialog'
// import VuejsDialogMixin from 'vuejs-dialog/vuejs-dialog-mixin.min.js'; // only needed in custom components
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
// Tell Vue to install the plugin.
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import vSelect from 'vue-select'
//vue toggler
import ToggleButton from 'vue-js-toggle-button'
import { directive as onClickOutside } from 'vue-on-click-outside'
Vue.directive('on-click-outside', onClickOutside)
Vue.use(ToggleButton)
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import Offline from './components/offlinePage2'

import 'vue-search-select/dist/VueSearchSelect.css'

// Ag Grid License
import { LicenseManager } from 'ag-grid-enterprise'
import apiHelper from "@/services/apiHelper";


// Tell Vue to install the plugin.

LicenseManager.setLicenseKey('[TRIAL]_12_August_2019_[v2]_MTU2NTU2ODAwMDAwMA==6e1789faaa22fa7102218a4d5776cfca')
const datepickerOptions = {
  sundayFirst: true
}
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('v-select', vSelect)
// todo
// cssVars()
Vue.use(VuejsDialog)
Vue.use(multiselect)
Vue.use(VueFormWizard)
Vue.use(BootstrapVue)
Vue.use(AirbnbStyleDatepicker, datepickerOptions)

if (!navigator.onLine) {
  Swal.fire({
    imageUrl: './img/no internet.jpg',
    imageHeight: 300,
    imageWidth: 500,
    imageAlt: 'No Internet',
    confirmButtonText: 'Try Again'
  }).then((result) => {
    if (result.value) {
      location.reload();
    }
  })
}
if( window.localStorage.getItem('token')){
  /*
  new Promise((resolve, reject) => {
    apiHelper({
      url: "/currentUser",
      method: "GET"
    })
      .then(data => {
        let data1 = data
        console.log(data);
        const userInfo = data.data[0];
        Vue.use(VueGtag, {
          pageTrackerScreenviewEnabled: true,
          config: { id: 'UA-188441903-1',
            params: {
              user_id: userInfo.emailHash
            }
          },
        },router)
      })
      .catch(error => {
      });
  })
  */
}


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  beforeMount() {
    console.log("Before Mount")
  },
  mounted() {
  },
  methods: {
      // invokeParentMethod(event) {
          
      // }
  },
  components: {
    App
  },
  
})

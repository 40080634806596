import apiHelper from '@/services/apiHelper'
import { stat } from 'fs';
import VueGtag from 'vue-gtag'
import Vue from 'vue'
import router from '../router'
export const login = {
  namespaced: true,

  state: {
    loginState: {},
    loader: false,
    errorState: {
      flag: false,
      message: ''
    },
    errorStateFoegotPassword: {
      flag: false,
      message: ''
    },
    successStatus: {}
  },

  getters: {
    getLoginState: (state) => {
      return state.loginState
    },
    getLoader: (state) => {
      return state.loader
    },
    getErrorState: (state) => {
      return state.errorState
    },
    getSuccessStatus: (state) => {
      return state.successStatus
    },
    getErrorStatus: (state) => {
      return state.errorStateFoegotPassword
    }
  },

  mutations: {
    setLoginState: (state, payload) => {
      state.loginState = payload
    },
    setLoader: (state, payload) => {
      state.loader = payload
    },
    setAuthStatus: (state, payload) => {
      state.errorState = payload
    },
    setSuccessStatus: (state, payload) => {
      state.successStatus = payload
    },
    setErrorStatus: (state, payload) => {
      state.errorStateFoegotPassword = payload
    }
  },

  actions: {

    login: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: '/login',
          method: 'POST',
          data: payload
        }).then(authStatus => {
         /*
            Vue.use(VueGtag, {
              pageTrackerScreenviewEnabled: true,
              config: { id: 'UA-188441903-1',
              params: {
                user_id: authStatus.emailHash
              }
             }
            },router
          )
         */
          window.localStorage.setItem('token', authStatus.token)
          window.localStorage.setItem('emailId', payload.emailID)
          window.localStorage.setItem('status', authStatus.status)
          window.localStorage.setItem('userName', authStatus.userName)
          window.localStorage.setItem('name', authStatus.name)
          window.localStorage.setItem('admin', authStatus.admin)
          window.localStorage.setItem('emailHash', authStatus.emailHash)
          window.localStorage.removeItem('strategyScreen');
          commit('setLoginState', authStatus)
          resolve(authStatus)
        })
          .catch(error => {
            reject(error)
            commit('setAuthStatus', error.status)
          })
      })
    },
    forgotPassword: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: '/forgotPassword',
          method: 'POST',
          data: payload
        }).then(authStatus => {
          commit('setSuccessStatus', authStatus)
          resolve(authStatus)
        })
          .catch(error => {
            reject(error)
            commit('setErrorStatus', error.status)
          })
      })
    },
    resetPassword: ({ commit }, payload) => {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
      apiHelper({
        url: '/resetPassword',
        method: 'POST',
        data: payload
      }).then(authStatus => {
        resolve(authStatus)
        commit('setLoader', false)
      })
      .catch(error => {
          commit('setLoader', false)
          reject(error)
        })
      })
    }
  }
}

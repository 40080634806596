import apiHelper from "@/services/apiHelper";
import router from "@/router";
import { cloneDeep, filter, concat } from "lodash";
import { format, areRangesOverlapping } from "date-fns";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// import dummy from './dummy'

function dataModification(stage, jsonData) {
  switch (stage) {
    case "2":
      jsonData.articles.selections.forEach(element => {
        if (element.is_dc09) {
          element.supplyingSource = 1;
        }
        element["deliveryDate"] = filter(
          jsonData.allocationTemplateFileContents,
          v => v.articleID == element.article
        )[0].deliveryDate;
        element["seasonStartFiscalWeek"] = filter(
          jsonData.allocationTemplateFileContents,
          v => v.articleID == element.article
        )[0].seasonStartFiscalWeek;
      });

      jsonData.articles.exclusions = concat(
        jsonData.articles.exclusions,
        filter(
          jsonData.articles.selections, 
          v => (v.lms || []).length === 0 || ((v.ohi || []).length === 0 && (v.ohia || []).length === 0 && (v.ooi || []).length === 0 && (v.ooia || []).length === 0)
        )
      );
      jsonData.articles.selections = filter(
        jsonData.articles.selections,
        v => (v.lms || []).length !== 0 && ((v.ohi || []).length !== 0 || (v.ohia || []).length !== 0 || (v.ooi || []).length !== 0 || (v.ooia || []).length !== 0)
      );
      break;
    case "4":
      var newData = [];
      jsonData.strategy.forEach(element => {
        var newObjStrategy = {};
        newObjStrategy = element;
        if (element.availableInventory < 0) {
          element.availableInventory = 0;
        }
        if (jsonData.allocationBasics.push === "CLEANOUT") {
          newObjStrategy.targetAllocationPercentage = (element.targetAllocationPercentage || 100)
          newObjStrategy.targetAllocationUnits = element.totalBuy;
        }
        let articleObj = jsonData.articles.selections.find(
          article => Number(article.article) === Number(element["productID"])
        );
        newObjStrategy.article_descr = articleObj.article_descr;
        newObjStrategy.merchCategory = articleObj.merch_cat_descr;
        newObjStrategy.category = articleObj.category_descr;
        newObjStrategy.Target_index = element.Target_index;
        newObjStrategy["merchCategoryID"] = articleObj.merch_cat;
        newObjStrategy["categoryID"] = articleObj.category;
        newObjStrategy.collection = articleObj.collection;
        newData.push(newObjStrategy);
      });
      jsonData.strategy = newData;
      break;
    case "5":
      /************************************************************* remove DC Level Code Computation *********************************** */
      // let returnedObj1 = [];
      // let len1 = jsonData.finalizeAllocation.length;
      // let obj = {};

      // for (let i = 0; i < len1; i++) {
      //   let aa = jsonData.finalizeAllocation[i];
      //   let xx = Number(aa["Article ID"]);
      //   let supplyxx = aa["Supplying DC"];
      //   //let x = obj[xx+supplyxx]
      //   if (typeof obj[xx + supplyxx] !== "undefined") {
      //     obj[xx + supplyxx].push(aa);
      //   } else {
      //     obj[xx + supplyxx] = [aa];
      //   }
      // }
      // let sum = 0;

      // jsonData.strategy.forEach(element => {
      //   let tempObj = {};
      //   tempObj = element;
      //   tempObj.allocatedTillDate =
      //     element.inSeasonSales + element.storeTotalOH + element.storeTotalOO;
      //   if (obj[Number(tempObj.productID) + tempObj.supplyingDC]) {
      //     sum = obj[Number(tempObj.productID) + tempObj.supplyingDC].reduce(
      //       function(a, b) {
      //         return parseInt(a) + parseInt(b.Allocation);
      //       },
      //       0
      //     );
      //   } else {
      //     sum = 0;
      //   }
      //   tempObj.allocation = sum;
      //   tempObj.dcInventoryPostAllocation =
      //     element.availableInventory - tempObj.allocation;
      //   returnedObj1.push(tempObj);
      // });
      // jsonData.getDcLevelData = returnedObj1;
      /************************************************************* remove DC Level Code Computation *********************************** */

      var siteData = JSON.parse(window.localStorage.getItem("siteData"));
      var fianlData = [];

      let selectedArticles = jsonData.articles.selections;
      let lenSelArt = selectedArticles.length;
      let articlesSelected = {};

      for (let i = 0; i < lenSelArt; i++) {
        articlesSelected[Number(selectedArticles[i].article)] =
          selectedArticles[i];
      }
      let lenSelSite = siteData.length;
      let siteSelected = {};

      for (let i = 0; i < lenSelSite; i++) {
        siteSelected[Number(siteData[i].store_id)] = siteData[i];
      }
      let returnedObj = [];
      let len = jsonData.finalizeAllocation.length;
      let tempObj = null;
      for (let i = 0; i < len; i++) {
        tempObj = null;
        tempObj = jsonData.finalizeAllocation[i];

        let articleObj =
          articlesSelected[
            Number(jsonData.finalizeAllocation[i]["Article ID"])
          ];
        if (!tempObj.hasOwnProperty("Max")) {
          tempObj["Max"] = "NA";
        }
        tempObj["merch_cat_descr"] = articleObj.merch_cat_descr;
        tempObj["article_descr"] = articleObj.article_descr;
        tempObj["category_descr"] = articleObj.category_descr;
        tempObj["shop_descr"] = articleObj.shop_descr;
        tempObj["business_descr"] = articleObj.business_descr;
        tempObj["customer_group_descr"] = articleObj.customer_group_descr;
        tempObj.collection = articleObj.collection;

        let siteObj =
          siteSelected[Number(jsonData.finalizeAllocation[i]["Store ID"])];
        tempObj["corprate_tier"] = siteObj.corprate_tier;
        tempObj["supplying_dc"] = siteObj.supplying_dc;
        tempObj["region"] = siteObj.region;
        tempObj["sales_district"] = siteObj.sales_district;
        tempObj["state"] = siteObj.state;
        tempObj["city"] = siteObj.city;
        tempObj["postal_code"] = siteObj.postal_code;
        if (articleObj.supplyingSource == 1) {
          tempObj["supplying_dc"] = "NA";
        }
        fianlData.push(tempObj);
      }
      jsonData.finalizeAllocation = fianlData;
      fianlData = null;
      articlesSelected = {};
      siteSelected = {};
      break;
    case "6":
      break;
    default:
      break;
  }
}
const createAllocation = {
  namespaced: true,
  state: {
    errorHit: false,
    filterFlag: false,
    jsonData: null,
    tempJsonData: null,
    staticData: null,
    merchCatAndCat: null,
    loader: false,
    loaderText: "",
    poissonDistribution: null,
    mmNameProgramNameMatch: false,
    sites: [],
    results: null,
    SAPReports: null,
    DownloadReportURL: null,
    allocationReportURL: null,
    fetchMinMaxDateAndFW: null,
    adminSettingsData: [],
    loaderOnReset: false
  },
  getters: {
    getLoader: state => {
      return state.loader;
    },
    getLoaderText: state => {
      return state.loaderText;
    },
    getJsonData: state => {
      let jsonData = JSON.parse(state.jsonData);
      if(jsonData) {
          jsonData.articles.selections = jsonData.articles.selections.sort(
            (a, b) => Number(a.article) - Number(b.article)
          );

        if(jsonData.sites) {
          jsonData.sites = jsonData.sites.sort(
            (a, b) => Number(a.ArticleId) - Number(b.ArticleId)
          );
        }

        jsonData.articles.selections.forEach(article => {
          article.supplyingSource = article.is_dc09 ? 1 : 0;
        }); 
        jsonData.articles.exclusions.forEach(article => {
          article.supplyingSource = article.is_dc09 ? 1 : 0;
        }); 

      }
      return jsonData;
      
    },
    getTempJsonData: state => {
      let jsonData = JSON.parse(state.tempJsonData);
      if(jsonData) {
          jsonData.articles.selections = jsonData.articles.selections.sort(
            (a, b) => Number(a.article) - Number(b.article)
          );

        if(jsonData.sites) {
          jsonData.sites = jsonData.sites.sort(
            (a, b) => Number(a.ArticleId) - Number(b.ArticleId)
          );
        }

        jsonData.articles.selections.forEach(article => {
          article.supplyingSource = article.is_dc09 ? 1 : 0;
        }); 
        jsonData.articles.exclusions.forEach(article => {
          article.supplyingSource = article.is_dc09 ? 1 : 0;
        }); 

      }
      return jsonData;
    },
    getStaticData: state => {
      return state.staticData;
    },
    getMerchCatAndCat: state => {
      return state.merchCatAndCat;
    },
    getMmNameProgramNameMatch: state => {
      return state.mmNameProgramNameMatch;
    },
    getSitesByIds: state => {
      return state.sites;
    },
    getAllocationReport: state => {
      return JSON.parse(state.results);
    },
    getSAPReports: state => {
      return state.SAPReports;
    },
    getDownloadReport: state => {
      return state.DownloadReportURL;
    },
    getAllocationReportDownload: state => {
      return state.allocationReportURL;
    },
    getFetchMinMaxDateAndFW: state => {
      return state.fetchMinMaxDateAndFW;
    },
    getAdminSettingsData: state => {
      return state.adminSettingsData;
    },
    getPoissonDistribution: state => {
      return state.poissonDistribution;
    },
    getLoaderOnReset: state => {
      return state.loaderOnReset;
    }
  },
  mutations: {
    setLoader: (state, payload) => {
      state.loader = payload;
    },
    setLoaderText: (state, payload) => {
      state.loaderText = payload;
    },
    setArticleTable: (state, payload) => {
      state.articleTable = payload;
    },
    setMmNameProgramNameMatch: (state, payload) => {
      state.mmNameProgramNameMatch = payload;
    },
    setJsonData: (state, payload) => {
      state.jsonData = JSON.stringify(payload);
    },
    setTempJsonData: (state, payload) => {
      state.tempJsonData = JSON.stringify(payload);
    },
    setAllocationReport: (state, payload) => {
      state.results = JSON.stringify(payload);
    },
    setSitesByIds: (state, payload) => {
      state.sites = payload;
    },
    setSAPReports: (state, payload) => {
      state.SAPReports = payload;
    },
    setDownloadReport: (state, payload) => {
      state.DownloadReportURL = payload;
    },
    setAllocationReports: (state, payload) => {
      state.allocationReportURL = payload;
    },
    setFetchMinMaxDateAndFW: (state, payload) => {
      state.fetchMinMaxDateAndFW = payload;
    },
    setAdminSettingsData: (state, payload) => {
      state.adminSettingsData = payload;
    },
    setPoissonDistribution: (state, payload) => {
      state.poissonDistribution = payload;
    },
    setLoaderOnReset: (state, payload) => {
      state.loaderOnReset = payload;
    }
  },

  actions: {
    checkRefAndPoissonDistributionHasUpdate: ({ commit, state }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        return apiHelper({
          url:
            "/checkIfReferenceStoreMappingAndPoissonDistributionHasUpdatedValues",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);

            commit("setLoader", false);
          })
          .catch(error => {
            // reject(error);
          });
      });
    },
    fetchPoissonDistribution: ({ commit, state }) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/fetchLatestPoissonDistributionValue",
          method: "GET"
        })
          .then(data => {
            // resolve(data);
            if (data.status)
              commit("setPoissonDistribution", Number(data.pd).toFixed(2));
            commit("setLoader", false);
          })
          .catch(error => {
            // reject(error);
          });
      });
    },
    updatePoissonDistribution: ({ commit, state }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        return apiHelper({
          url: "/updatePoissonDistribution",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);

            commit("setLoader", false);
          })
          .catch(error => {
            // reject(error);
          });
      });
    },
    FetchAdminMetaData: ({ commit, state }) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/fetchAdminScreenMetaInfo",
          method: "GET"
        })
          .then(data => {
            resolve(data);
            commit("setLoader", false);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchMinMaxDateAndFW: ({ commit, state }, payload) => {
      apiHelper({
        url: "/fetchMinMaxDateAndFW",
        method: "GET"
      }).then(resp => {
        commit("setFetchMinMaxDateAndFW", resp.minMaxDateAndFW);
      });
    },
    findStoreIDsByStoreIDPrefix: ({ commit, state }, payload) => {
      // commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/findStoreIDsByStoreIDPrefix",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
            commit("setLoader", false);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    uploadAdminStoreIndex: ({ commit, state }, payload) => {
      commit("setLoader", true);
      let formData1 = new FormData();
      for (let property2 in payload) {
        formData1.append(property2, payload[property2]);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/uploadStoresIndexAndReferenceStoreMappingData",
          method: "POST",
          data: formData1
        })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    downloadStoreIndex: ({ commit, state }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/downloadStoresIndexAndReferenceStoreMappingData",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
            // commit("setAdminSettingsData", data.data)
            commit("setLoader", false);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateAdminSettingsData: ({ commit, dispatch }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/updateAdminScreenData",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    adminSettingsData: ({ commit, state }, payload) => {
      if (payload && payload.onReset) {
        commit("setLoaderOnReset", true);
      } else {
        commit("setLoader", true);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/fetchAdminScreenData",
          method: "GET"
        })
          .then(data => {
            commit("setAdminSettingsData", data);
            commit("setLoader", false);
            commit("setLoaderOnReset", false);
          })
          .catch(error => {
            commit("setLoader", false);
            commit("setLoaderOnReset", false);
            reject(error);
          });
      });
    },
    updateAllocationBasics: ({ commit, state }, payload) => {
      commit("setLoader", true);
      let formData = new FormData();
      for (var property1 in payload) {
        formData.append(property1, payload[property1]);
      }
      
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/updateAllocationBasics",
          method: "POST",
          data: formData
        })
        .then(data => {
            dataModification("2", data.jsonData);
            commit("setLoader", false);
            commit("setTempJsonData", data.jsonData);
            commit("setJsonData", data.jsonData);
            resolve(data);
          })
          .catch(error => reject(error))
          .finally(() => commit("setLoader", false))
      });
    },
    editTemplate: ({ commit, state }, payload) => {
      let formData = new FormData();
      for (var property1 in payload) {
        formData.append(property1, payload[property1]);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          // url: "/updateAllocationBasics",
          url: "/updateAllocationBasicsEditTemplate",
          method: "POST",
          data: formData
        })
        .then(data => {
            dataModification("5", data.jsonData);
            commit("setTempJsonData", data.jsonData);
            commit("setJsonData", data.jsonData);
            resolve(data);
          })
          .catch(error => reject(error));
      });
    },
    uploadFinalizeAllocationReport: ({ commit, state }, payload) => {
      commit("setLoader", true);
      let formData1 = new FormData();
      for (let property2 in payload) {
        formData1.append(property2, payload[property2]);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/uploadFinalAllocationData",
          method: "POST",
          data: formData1
        })
          .then(data => {
            commit("setLoader", false);
            if (!data.jsonData.finalizeAllocation[0].merch_cat_descr) {
              dataModification("5", data.jsonData);
            }
            // commit('setTempJsonData', data.jsonData)
            commit("setJsonData", data.jsonData);
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    downloadFinalizeAllocationReport: ({ commit }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: `/downloadAllocation?allocationID=${payload.allocationID}`,
          method: "GET",
          data: payload
        })
          .then(data => {
            commit("setLoader", false);
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    CalSumOfQtySold: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/calculateSumOfQuantitySold",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAllocation: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/deleteAllocation",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateSapReportUploadedFlag: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/updateSapReportUploadedFlag",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    checkIfAllocationNameExistsForCurrentUser: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/checkIfAllocationNameExistsForCurrentUser",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    uploadMinMaxTemplate: ({ commit }, payload) => {
      commit("setLoader", true);
      let formData1 = new FormData();
      for (let property2 in payload) {
        formData1.append(property2, payload[property2]);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/uploadLMMinMaxTemplate",
          method: "POST",
          data: formData1
        })
          .then(data => {
            commit("setLoader", false);
            dataModification("2", data.jsonData);
            // commit('setTempJsonData', data.jsonData)
            commit("setJsonData", data.jsonData);
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    downloadMinMaxTemplate: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/downloadLMMinMaxTemplate",
          method: "POST",
          data: payload
        })
          .then(data => {
            resolve(data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ArticleTable: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/getAllArticles",
          method: "GET",
          data: payload
        })
          .then(data => {
            commit("setArticleTable", data.jsonData);
            resolve(data.gridData);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    createAllocationStep1Next: ({ commit }, payload) => {
      commit("setLoader", true);
      var formData = new FormData();
      for (var property1 in payload) {
        formData.append(property1, payload[property1]);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/allocation",
          method: "POST",
          data: formData
        })
          .then(data => {
            dataModification("2", data.jsonData);
            commit("setTempJsonData", data.jsonData);
            commit("setJsonData", data.jsonData);
            commit("setLoader", false);
            resolve(data.jsonData);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    checkMMNameProgramCombination: ({ commit }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/checkMMNameProgramNameCombination",
          method: "POST",
          data: payload
        })
          .then(data => {
            commit("setLoader", false);
            // commit('setMmNameProgramNameMatch', data)
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    allocationByAllocationId: ({ commit }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/getAllocationByAllocationID",
          method: "POST",
          data: payload
        })
          .then(data => {
            if (
              data.status &&
              data.jsonData &&
              Object.keys(data.jsonData).length
            ) {
              dataModification(
                data.jsonData.allocationBasics.allocationStage,
                data.jsonData
              );
              commit("setTempJsonData", data.jsonData);
              commit("setJsonData", data.jsonData);
              resolve(data.jsonData);
              commit("setLoader", false);
            } else {
              commit("setLoader", false);
              Swal.fire({
                text: "No data found, please try another project.",
                icon: "warning",
                confirmButtonText: "Ok"
              }).then(result => {
                if (result.value) {
                  router.push("/dashboard");
                }
              });
            }
            // commit('setMmNameProgramNameMatch', data)
          })
          .catch(error => {
            if (error.code === 400 && !error.status) {
              console.error(error);
              console.dir(error);
              
              Swal.fire({
                // title: 'Error!',
                text: error.message,
                icon: "error",
                confirmButtonText: "Ok"
              }).then(result => {
                if (result.value) {
                  router.push("/dashboard");
                }
              });
            }
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    sitesByIds: ({ commit }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/getSitesByIDs",
          method: "POST",
          data: payload
        })
          .then(data => {
            commit("setSitesByIds", data.sites);
            commit("setLoader", false);
            window.localStorage.setItem("siteData", JSON.stringify(data.sites));
            // commit('setMmNameProgramNameMatch', data)
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    actionGetStaticData: context => {
      apiHelper({
        url: "/getAllMMNamesAndProgramNamesHierarchy",
        method: "GET"
      })
        .then(data => {
          context.state.staticData = data.mmNameProgramNameHierarchy;
        })
        .catch(error => {});
    },
    actionGetMerchCatAndCat: context => {
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/getMerchCatAndCat",
          method: "GET"
        })
          .then(data => {
            context.state.merchCatAndCat = data;
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    saveAllocationData: ({ commit }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/saveAllocationData",
          method: "POST",
          data: payload
        })
          .then(data => {
            dataModification(
              data.jsonData.allocationBasics.allocationStage,
              data.jsonData
            );
            if (data.jsonData.allocationBasics.allocationStage <= "6") {
              commit("setTempJsonData", data.jsonData);
              commit("setJsonData", data.jsonData);
            }
            commit("setLoader", false);
            if (data.jsonData.allocationBasics.allocationStage === "7") {
              router.push("/dashboard");
            }
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    saveAllocationDetails: ({ commit }, payload) => {
      commit("setLoader", true);
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/saveAllocationDetails",
          method: "POST",
          data: payload
        })
          .then(data => {
            commit("setLoader", false);
            commit("setTempJsonData", data.jsonData);
            commit("setJsonData", data.jsonData);
            resolve(data);
          })
          .catch(error => {
            commit("setLoader", false);
            reject(error);
          });
      });
    },
    AllocationReport: ({ commit }, payload) => {
      commit("setLoader", true);
      apiHelper({
        url: "/getAllocationReport",
        method: "POST",
        data: payload
      })
        .then(data => {
          commit("setLoader", false);

          commit("setAllocationReport", data.results);
          // commit('setJsonData', data.jsonData)
        })
        .catch(error => {
          commit("setLoader", false);
        });
    },
    AllocationReportDownload: ({ commit }, payload) => {
      commit("setLoader", true);
      apiHelper({
        url: "/downloadAllocationReport",
        method: "POST",
        data: payload
      })
        .then(data => {
          commit("setLoader", false);
          // commit('setJsonData', data)
          commit("setDownloadReport", data);
          // commit('setJsonData', data.jsonData)
        })
        .catch(error => {
          commit("setLoader", false);
        });
    },
    downloadSAPReports: ({ commit }, payload) => {
      commit("setLoader", true);
      apiHelper({
        url: "/sapInput",
        method: "GET",
        params: payload
      })
        .then(data => {
          commit("setLoader", false);
          commit("setSAPReports", data);
        })
        .catch(error => {
          commit("setLoader", false);
        });
    },
    DownloadAllocation: ({ commit }, payload) => {
      commit("setLoader", true);
      apiHelper({
        url: "/downloadAllocation",
        method: "GET",
        params: payload
      })
        .then(data => {
          commit("setLoader", false);
          commit("setAllocationReports", data);
        })
        .catch(error => {
          commit("setLoader", false);
        });
    },
    triggerDc09andDc20({ commit }, payload) {
      let { is_dc09, supplyingSource, is_dc20, dcTwenty, article } = payload;

      let jsonData = this.getters['CreateAllocation/getJsonData'];
      jsonData.articles.selections.forEach(item => {
        if (item.article == article) {
          item.is_dc09 = is_dc09;
          item.supplyingSource = supplyingSource;
          item.is_dc20 = is_dc20;
          item.dcTwenty = dcTwenty;
        }
      });

      commit("setJsonData", jsonData);
      setTimeout(() => payload.callback && payload.callback(), 10);
    }
  }
};

export default createAllocation;

import apiHelper from '@/services/apiHelper'
// import dummy from './dummy'
import { format } from 'date-fns'
import { filter } from "lodash";

const dashboard = {
  namespaced: true,
  state: {
    dashboardTable: [],
    loader: false
  },

  getters: {
    getLoader: state => { return state.loader },
    getDashboardTable: state => { return state.dashboardTable }
  },

  mutations: {
    setLoader: (state, payload) => {
      state.loader = payload
    },
    setDashboardTable: (state, payload) => {
      state.dashboardTable = payload
    }
  },

  actions: {
    dashboardTable: ({ commit }) => {
      commit('setLoader', true);
      // commit('setLoaderText', "20%");
      return new Promise((resolve, reject) => {
        apiHelper({
          url: '/getAllocationsOfCurrentUser',
          method: 'GET',
        }).then(data => {
          commit('setLoader', false);
          // commit('setLoaderText', '100%');
          // data.allocations.forEach(obj => {
          //   obj.updatedat = format(obj.updatedat, 'MM/DD/YYYY')
          //   obj.createdat = format(obj.createdat, 'MM/DD/YYYY')
          //   if (obj.allocationdate === null) {
          //     obj.allocationdate = 'NA'
          //   } else {
          //     obj.allocationdate = format(obj.allocationdate, 'MM/DD/YYYY')
          //   }
          // })
          commit('setDashboardTable', data.allocations)
          resolve(data.allocations)
        })
          .catch(error => {
            commit('setLoader', false)
            reject(error)
          })
      })
    },
    fetchDataLastLoadedInformation: ({ commit }) => {
      apiHelper({
        url: '/fetchDataLastLoadedInformation',
        method: 'GET',
      }).then(resp => {
        resp.data.forEach(data => {
          data.updatedOn = format(data.updatedOn, "MM/DD/YYYY")
        })
        window.localStorage.setItem('dataUpdateInfo', JSON.stringify(resp.data))
      })
        .catch(error => {
          console.log(error)
        })
    },
    UploadPreAllocationReportInput: ({ commit }, payload) => {
      commit('setLoader', true)
      let formData = new FormData()
      for (let property in payload) {
        formData.append(property, payload[property])
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: '/uploadPreAllocationReportInput',
          method: 'POST',
          data: formData
        }).then(data => {
          commit('setLoader', false)
          // commit('setJsonData', data.jsonData)
          resolve(data)
        })
          .catch(error => {
            commit('setLoader', false)
            reject(error)
          })
      })
    },
    regenerateTicket: ({ commit }, payload) => {
      let formData = new FormData()
      for (let property in payload.file) {
        console.log(property, payload.file[property], 'forinnnnnnnnnnnnnn')
        formData.append(property, payload.file[property])
      }
      console.log(payload, formData, 'formDataformDataformDataformDataformDataformDataformDataformDataformDataformData')
      return new Promise((resolve, reject) => {
        apiHelper({
          url: `${payload.url}`,
          method: 'POST',
          data: formData
        }).then(data => {
          resolve(data)
        })
          .catch(error => {
            reject(error)
          })
      })
    },
    createTicket: ({ commit }, payload) => {
      var formData = new FormData();
      for (var property1 in payload) {
        formData.append(property1, payload[property1]);
      }
      return new Promise((resolve, reject) => {
        apiHelper({
          url: "/createTicket",
          method: 'POST',
          data: formData
        }).then(data => {
          resolve(data)
        })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}

export default dashboard

export const PRODUCTION_ENDPOINT = "/";
export const DEVELOPMENT_ENDPOINT = "https://joann-allocation-dev.impactanalytics.co/";
// export const DEVELOPMENT_ENDPOINT = "http://localhost:8001/";
export const TEST_ENDPOINT = "/";

// Staging Url:
// https://joann-allocation-staging.impactanalytics.co

// for deployment, remove the url and keep "/"
// e.g.: export const DEVELOPMENT_ENDPOINT = "/";

import Vue from "vue";

export default Vue.extend({
  template: `
      <div>
        <b-modal
        :no-stacking="true"
        :no-enforce-focus="true"
        :no-fade="true"
        :no-close-on-backdrop="true
          :no-close-on-esc="true"
          :hide-header-close="true"
          v-model="show"
          @ok="onTryAgain"
          title="No Internet"
          ok-title="Try Again"
          :cancel-disabled="true"
          ok-only
        >
          <p class="my-4" align="center">No Internet connection found</p>
      <p class="my-4" align="center">Check your connection or try again</p>
      <template slot="showModal" slot-scope="{ ok }">
        <b>Custom Footer</b>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="ok()">OK</b-button>
      </template>
        </b-modal>
      </div>
    `,
  data: function () {
    return {
      show: false
    };
  },
  created() {
    this.show = true
  },
  methods: {
    onTryAgain() {
      location.reload();
    }
  }
});
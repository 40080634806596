import Vue from 'vue'
import Router from 'vue-router'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Pages
const Dashboard = () => import('@/pages/dashboard')
const ResetPassword = () => import('@/pages/ResetPassword')
const CompletedAllocation = () => import('@/components/Dashboard/CompletedAllocation')
const Login = () => import('@/pages/Login')
const CreateAllocation = () => import('@/pages/CreateAllocation')
const MaintenancePage = () => import('@/pages/Maintenance')
const Settings = () => import('@/pages/Settings')
const GenerateTicket = () => import('@/pages/GenerateTicket')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/',
      redirect: '/login',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [{
        path: 'login',
        name: 'Login',
        component: Login
      }]
    },
    {
      path: '/',
      redirect: '/maintenance',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [{
        path: 'maintenance',
        name: 'Maintenance Page',
        component: MaintenancePage
      }]
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'resetPassword',
          name: 'Reset Password',
          component: ResetPassword
        },
        {
          path: 'settings',
          name: 'Settings',
          component: Settings
        },
        {
          path: 'ticketGenertate',
          name: 'Generate Ticket',
          component: GenerateTicket
        },
        {
          path: '/completedAllocation',
          name: 'Completed Allocation',
          component: CompletedAllocation
        },
        {
          path: '/createAllocation/:allocationId?',
          name: 'Create Allocation',
          component: CreateAllocation
        }
      ]
    }
  ]
})
